const contentsSlider01 = new Swiper( '.swiper-contentsSlider-01', {
	//ここに設定値を書いていく,
	navigation: {
		nextEl: '.swiper-button-next-01',
		prevEl: '.swiper-button-prev-01',
	},
	loop: true,
	slidesPerView: 1,
	spaceBetween: 30,
	breakpoints: {
		// スライドの表示枚数：500px以上の場合
		768: {
			slidesPerView: 2,
		},
		1280: {
			slidesPerView: 3,
		},
	},
} );
const contentsSlider02 = new Swiper( '.swiper-contentsSlider-02', {
	//ここに設定値を書いていく,
	navigation: {
		nextEl: '.swiper-button-next-02',
		prevEl: '.swiper-button-prev-02',
	},
	loop: true,
	slidesPerView: 1,
	spaceBetween: 30,
	breakpoints: {
		// スライドの表示枚数：500px以上の場合
		768: {
			slidesPerView: 2,
		},
		1280: {
			slidesPerView: 3,
		},
	},
} );
const contentsSlider03 = new Swiper( '.swiper-contentsSlider-03', {
	//ここに設定値を書いていく,
	navigation: {
		nextEl: '.swiper-button-next-03',
		prevEl: '.swiper-button-prev-03',
	},
	loop: true,
	slidesPerView: 1,
	spaceBetween: 30,
	breakpoints: {
		// スライドの表示枚数：500px以上の場合
		768: {
			slidesPerView: 2,
		},
		1280: {
			slidesPerView: 3,
		},
	},
} );
const SliderListRecrute = new Swiper( '.swiper-recrute', {
	//ここに設定値を書いていく,
	navigation: {
		nextEl: '.swiper-button-next-recrute',
		prevEl: '.swiper-button-prev-recrute',
	},
	loop: true,
	slidesPerView: 1,
	spaceBetween: 45,
	breakpoints: {
		// スライドの表示枚数：500px以上の場合
		768: {
			slidesPerView: 2,
		},
		1280: {
			slidesPerView: 3,
		},
	},
} );

// スクロールフェードインアニメーションの個別設定（ScrollReveal.js）
ScrollReveal().reveal( '.js__fadeBottom01', {
	// delay: 300, // アニメーション開始までの時間
	duration: 1200, // アニメーション完了にかかる時間
	origin: 'bottom', // 要素がどの方向から来るか
	distance: '50px', // 移動する距離
	reset: false, // フレームインの度に動かすか
} );
ScrollReveal().reveal( '.js__fadeBottom02', {
	delay: 200, // アニメーション開始までの時間
	duration: 600, // アニメーション完了にかかる時間
	origin: 'bottom', // 要素がどの方向から来るか
	distance: '50px', // 移動する距離
	reset: false, // フレームインの度に動かすか
} );

ScrollReveal().reveal( '.js__fadeLeft', {
	delay: 500, // アニメーション開始までの時間
	duration: 600, // アニメーション完了にかかる時間
	origin: 'left', // 要素がどの方向から来るか
	distance: '150px', // 移動する距離
	reset: false, // フレームインの度に動かすか
} );

ScrollReveal().reveal( '.js__fadeRight', {
	delay: 500, // アニメーション開始までの時間
	duration: 600, // アニメーション完了にかかる時間
	origin: 'right', // 要素がどの方向から来るか
	distance: '150px', // 移動する距離
	reset: false, // フレームインの度に動かすか
} );

// マーカーアニメーション
const addMarkerBlueClass = ( entries, obs ) => {
	entries.forEach( ( entry ) => {
		if ( entry.isIntersecting ) {
			entry.target.classList.add( 'inview' );
			// 一度クラスを追加したら監視をやめる
			obs.unobserve( entry.target );
		}
	} );
};

const markerBlueObserver = new IntersectionObserver( addMarkerBlueClass );

const markerBlueElements = document.querySelectorAll( '.js_markerBlue' );
markerBlueElements.forEach( ( markerBlueElement ) => {
	markerBlueObserver.observe( markerBlueElement );
} );

const header = document.getElementById( 'header' );
window.addEventListener( 'scroll', () => {
	if ( window.scrollY > 200 ) {
		header.classList.add( 'is_hide' );
	} else {
		header.classList.remove( 'is_hide' );
	}
	if ( window.scrollY > window.innerHeight / 2 ) {
		header.classList.add( 'is_transition' );
	} else {
		header.classList.remove( 'is_transition' );
	}
	if ( window.scrollY > window.innerHeight ) {
		header.classList.add( 'is_show' );
	} else {
		header.classList.remove( 'is_show' );
	}
} );
